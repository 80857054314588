import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';
import React, { useState, Fragment } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'react-emotion';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import CartButtonComponent from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import MainNav from './MainNav';
// import SearchFieldWrapper from './SearchField.js';
import { SearchField } from './Search/';
import Button from '../../ui/Button';
import get from 'lodash.get';
import MobileMenu from './MobileMenu';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import AutocompleteQuery from './AutocompleteQuery.gql';
import cartQuery from '../../Cart/CartQuery.gql';

import { ReactComponent as CarrotSvg } from '../../../svg/Carrot.svg';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { default as StickyComponent } from 'react-stickynode';
import { ChannelSelectorFlyout } from './ChannelSelector/ChannelSelector';

import Logo from './Logo';
import ChannelBanner from './ChannelSelector/RecommendedChannel/ChannelBanner';

import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';

const Container = styled('header')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  white-space: nowrap;
  background: ${theme('colors.white')};
`;

const Middle = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  max-width: ${theme('general.maxWidth')};
  padding: 1rem;
  ${theme('below.md')} {
    padding: 0;
  }

  ${p => p.theme.below.sm} {
    justify-content: center;
  }
`;

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 1rem;
  }
`;

const Checkout = styled(Button)`
  width: auto;
  margin-left: 1.2rem;
  display: flex;
  background-color: ${theme('colors.checkoutButton.background')};
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  height: auto;
  padding-top: 0.6rem;

  &:hover {
    background-color: ${theme('colors.checkoutButton.hover')};
  }
  > a {
    color: ${theme('colors.white')};
  }
`;

const CartButton = styled(CartButtonComponent)``;

const Carrot = styled(CarrotSvg)`
  transform: rotate(-90deg) scale(0.8);
  margin-left: 0.5rem;
  fill: ${theme('colors.white')};
`;

const MenuButton = styled('button')`
  display: flex;
  align-items: center;
  font-size: 12px;
  background: transparent;
  height: 100%;
  padding: 0 20px;

  &:first-child {
    border-right: 1px solid #4b216a;
    & > svg {
      width: 24px;
      height: 16px;
    }
  }
`;

const MobileNav = styled('nav')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const SearchButton = styled(MenuButton)`
  align-items: center;
  svg {
    width: 19px;
    height: 18px;
  }
`;

const ButtonText = styled.span`
  color: #fff;
  text-transform: uppercase;
  display: none;
  font-size: 18px;
  margin-left: 5px;

  ${p => p.theme.between['sm-lg']} {
    display: block;
  }
`;

const Sticky = styled(StickyComponent)`
  width: 100%;
  &.active {
    .sticky-inner-wrapper {
      z-index: 998;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    }
  }
`;

const CheckoutButton = () => {
  return (
    <CartProvider query={cartQuery}>
      {result => {
        const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');

        return (
          <Checkout anchor={true} href={checkoutUrl}>
            {t('Check out')}
            <Carrot />
          </Checkout>
        );
      }}
    </CartProvider>
  );
};

const Header = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const { data, loading } = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  return (
    !loading && (
      <>
        <ChannelBanner />
        <ChannelSelectorFlyout />
        <Container>
          <Middle>
            <Logo />
            <Above breakpoint="lg">
              {matches => (matches ? <LanguageSelector /> : null)}
            </Above>
            <Above
              breakpoint="lg"
              render={() => (
                <Wrapper>
                  <SearchField
                    autocompleteQuery={AutocompleteQuery}
                    onCancel={() => {}}
                    onOpen={() => {}}
                    isOpen={true}
                    alwaysOpen={true}
                  />
                  <CartButton />
                  <CheckoutButton />
                </Wrapper>
              )}
            />
          </Middle>
          <Above breakpoint="lg">
            <MainNav categories={data?.categories || []} />
          </Above>

          <Below
            breakpoint="lg"
            render={() => (
              <Fragment>
                <Sticky>
                  <MobileNav>
                    <DrawerTrigger preventOverflow={true} id="mobile-drawer">
                      {drawer => {
                        return (
                          <MenuButton
                            onClick={
                              drawer.isOpen
                                ? drawer.hideTarget
                                : drawer.showTarget
                            }
                          >
                            <Menu />
                            <ButtonText>{t('Menu')}</ButtonText>
                          </MenuButton>
                        );
                      }}
                    </DrawerTrigger>
                    <SearchButton onClick={() => setSearchOpen(!searchOpen)}>
                      <Search />
                      <ButtonText>{t('Search')}</ButtonText>
                    </SearchButton>
                    <SearchField
                      onCancel={() => setSearchOpen(true)}
                      onOpen={() => setSearchOpen(true)}
                      isOpen={searchOpen}
                    />
                    <CartButton>
                      <ButtonText>{t('Cart')}</ButtonText>
                    </CartButton>
                  </MobileNav>
                </Sticky>
                <MobileMenu categories={data?.categories || []} />
              </Fragment>
            )}
          />
          <CartFlyout />
        </Container>
      </>
    )
  );
};

export default Header;
