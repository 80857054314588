import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import { FacebookProvider, Page } from 'react-facebook';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import MaxWidth from '../MaxWidth';
import FooterLinks from './FooterLinks';

const Conatiner = styled('footer')`
  background: ${({ theme }) => theme.colors.footer.background};
  padding: 0;
  margin: 0;
`;

const Wrapper = styled(MaxWidth)`
  text-align: left;
  font-size: 0.875rem;
  h2 {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }
`;

const NewsletterWrapper = styled('div')`
  min-width: 25%;
`;

const FacebookWrapper = styled('div')`
  > div {
    text-align: left;
  }
`;

const Image = styled('img')`
  /* max-height: 15rem; */
  /* max-width: 24rem; */
  height: auto;
  display: block;
  width: auto;
  max-width: 100%;

  ${p => p.theme.only.lg} {
    // max-height: 185px;
  }
`;

const boxPadding = '1rem';

const WrapFooterNav = styled('div')`
  border-top: 1px solid #dfdede;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  /* padding: 2rem 1.5rem; */
  padding: 20px;
  ${({ theme }) => theme.below.lg} {
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    > * {
      width: 50%;
    }

    ${NewsletterWrapper} {
      order: 4;
      /* padding: ${boxPadding}; */
      padding: 0;
    }
    ${FacebookWrapper} {
      order: 3;
      padding: ${boxPadding};
    }

    ${Image} {
      order: 2;
      padding: ${boxPadding};
    }
  }

  ${({ theme }) => theme.below.md} {
    flex-direction: column; 

    > * {
      width: 50%;
    }

    ${Image} {
      order: 3;
    }
  }

  ${({ theme }) => theme.below.sm} {
    > * {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    ${NewsletterWrapper} {
      /* padding-left: ${boxPadding}; */
    }
  }
`;

const facebookURLs = {
  1: 'barnresebutiken',
  2: 'Matkamuksucom-445735285489471',
  3: 'Reisipisik'
};

const paymentImageURLs = {
  1: 'Barnreseebutiken-Footer-8-Oct-2019.png',
  2: 'Matkamuksu-Footer-8-Oct-2019.png',
  3: 'Reisipisik-Footer-8-Oct-2019.jpg',
  4: '5a-Footer-DK.png'
};

const FacebookWidget = ({ channelId }) => {
  const facebookURL = facebookURLs[channelId];

  return (
    <FacebookProvider appId="563083593841589">
      <Page
        href={`https://www.facebook.com/${facebookURL}/`}
        tabs="timeline"
        width={270}
        height={130}
      />
    </FacebookProvider>
  );
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const channelId = selectedChannel.id;

  const paymentImage = paymentImageURLs[channelId];

  return (
    <Conatiner>
      <Wrapper>
        <WrapFooterNav>
          <FooterLinks />
          <Image
            src={`https://www.barnresebutiken.se/pub_images/medium/${paymentImage}`}
            alt=""
          />
          {channelId === 3 && (
            <FacebookWrapper>
              <FacebookWidget channelId={channelId} />
            </FacebookWrapper>
          )}
          {channelId !== 6 && (
            <NewsletterWrapper>
              <NewsletterField />
            </NewsletterWrapper>
          )}
        </WrapFooterNav>
      </Wrapper>
    </Conatiner>
  );
};

export default Footer;
