import React from 'react';
import styled from 'react-emotion';
import { withCookies } from 'react-cookie';

import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { cx } from 'emotion';
import { Query } from 'react-apollo';
import pageQuery from './PageQuery.gql';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';

const PopupModal = styled('div')`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

const Wrapper = styled('div')`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 840px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 50;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
  z-index: 10;

  &.visible {
    opacity: 1;
  }

  .close-button {
    stroke: black;
    cursor: pointer;
    position: absolute;
    padding: 0 1rem 1.5rem 1.5rem;
    right: 0;
  }

  [data-flight-image-container] {
    margin: 1rem 0;
    padding-bottom: 56.6% !important;
    /* padding-bottom: 41.45% !important; */ /* Purchase stop only */
    height: 100% !important;
    width: 100% !important;
    img {
      object-position: left !important;
    }
  }

  ${theme.above.md} {
    width: 100%;
    top: 50%;

    .close-button {
      padding: 0 1rem 0 0;
    }
  }
`;

const PopupContent = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;

  h1,
  h2,
  h3 {
    color: ${theme.colors.primary};
  }

  ${theme.below.md} {
    width: 95%;
    h1,
    h2,
    h3 {
      span {
        font-size: 1.6rem !important;
        line-height: 2.3rem;
      }
    }
  }
`;

const Popup = ({ cookies, pageId = 81 }) => {
  const popuptimer = 10000;
  const { popupCookie } = cookies.cookies;
  const [showPopup, setShowPopup] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  const closeModal = () => {
    cookies.set('popupCookie', 'closed', {
      maxAge: 60 * 60 * 24 * 30
    });

    setIsVisible(false);
    setTimeout(() => {
      setShowPopup(false);
    }, 200);
  };

  React.useEffect(() => {
    if (popupCookie !== 'closed') {
      setTimeout(() => {
        setShowPopup(true);

        setTimeout(() => {
          setIsVisible(true);
        }, 200);
      }, popuptimer);
    }
  }, [popupCookie]);

  return (
    <Query query={pageQuery} variables={{ id: pageId }}>
      {data =>
        !data ? null : (
          <>
            {showPopup && (
              <>
                <PopupModal onClick={closeModal}></PopupModal>
                <Wrapper
                  className={cx(
                    'popup-wrapper',
                    isVisible ? 'visible' : undefined
                  )}
                >
                  <div className={'close-button'}>
                    <Cross onClick={closeModal} />
                  </div>
                  <PopupContent
                    className={'popup-content'}
                    dangerouslySetInnerHTML={{
                      __html: data?.data?.page?.content
                    }}
                  />
                  {data?.data?.page?.images?.length > 0 && (
                    <Image contain src={data?.data?.page?.images} />
                  )}
                </Wrapper>
              </>
            )}
          </>
        )
      }
    </Query>
  );
};

export default withCookies(Popup);
