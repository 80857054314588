import Accordion, { AccordionContent } from '@jetshop/ui/Accordion/Accordion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as CarrotSvg } from '../../../../svg/Carrot.svg';
import t from '@jetshop/intl';

const List = styled.ul``;

const Item = styled.li`
  &.hide > div:nth-child(2) {
    max-height: 0;
  }

  ${p =>
    p.child &&
    `
    margin-left: 1rem;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(CategoryLink)`
  color: #111;
  font-weight: bold;
  padding: 0.2rem 0;
  font-size: 1.1rem;
  text-decoration: none;
  display: block;
  white-space: normal;
`;

const DropdownToggler = styled.button`
  background: none;
  color: #111;
  padding: 0.2rem 0;
  font-size: 1.1rem;
  color: black;
  text-decoration: none;
  font-weight: bold;
  display: block;
  white-space: normal;
  font-weight: ${p => (p.child ? 'normal' : 'bold')};
`;

const SubCategoryLink = styled(StyledLink)`
  font-weight: normal;
`;

const SeeAll = styled(SubCategoryLink)`
  margin-left: 1rem;
`;

const Carrot = styled(CarrotSvg)`
  transform: rotate(-90deg);
  transition: transform 0.3s;
  fill: ${({ theme }) => theme.colors.primary};
  ${p => p.open && `transform: rotate(0deg);`}
`;

const MobileCategories = ({ categories, closeMenu, child = false }) => (
  <nav aria-label="Main">
    <Accordion single>
      {({ handleClick, openIndexes }) => (
        <List>
          {categories.map(category => {
            if (category.id === 1286 && category.level === 1) {
              // Att resa med barn
              return null;
            }

            const isOpen = openIndexes.includes(category.id);
            const hasSubcategories =
              category.subcategories && category.subcategories.length > 0;

            const isNotOpenAndSubcategory = !isOpen && child;

            return (
              <Item
                key={category.id}
                className={isNotOpenAndSubcategory && 'hide'}
                child={child}
              >
                {hasSubcategories ? (
                  <>
                    <Wrapper
                      onClick={() => handleClick(category.id)}
                      child={child}
                    >
                      <DropdownToggler child={child}>
                        {category.name}
                      </DropdownToggler>
                      <Carrot open={isOpen} />
                    </Wrapper>
                    <AccordionContent isOpen={isOpen}>
                      <SeeAll category={category} onClick={closeMenu}>
                        {t('See all')}
                      </SeeAll>
                      <MobileCategories
                        categories={category.subcategories}
                        closeMenu={closeMenu}
                        child
                      />
                    </AccordionContent>
                  </>
                ) : child ? (
                  <SubCategoryLink category={category} onClick={closeMenu}>
                    {category.name}
                  </SubCategoryLink>
                ) : (
                  <StyledLink category={category} onClick={closeMenu}>
                    {category.name}
                  </StyledLink>
                )}
              </Item>
            );
          })}
        </List>
      )}
    </Accordion>
  </nav>
);

export default MobileCategories;
