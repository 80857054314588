import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import cartQuery from './CartQuery.gql';

import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';

import { Price as PriceComponent } from '@jetshop/ui/Price';
import { ReactComponent as CartSvg } from '../../svg/Cart.svg';
import { ReactComponent as CarrotSvg } from '../../svg/Carrot.svg';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  :hover {
    color: ${theme('colors.black')};
  }
  svg {
    height: 18px;
  }
`;

const Price = styled(PriceComponent)`
  margin-left: 0.5rem;
  font-size: 18px;
  display: inline;
  > div {
    display: inline;
  }
  ${theme('below.lg')} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const CartButtonWrapper = styled('div')`
  padding: 0 20px;
`;

const CartIcon = styled(CartSvg)``;

const Carrot = styled(CarrotSvg)`
  margin-left: 0.5rem;
`;

const Badge = styled('span')`
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.white};
  color: #662d91;
  border-radius: 1.1rem;
  min-width: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0.3rem;
  text-align: center;
  font-size: 0.7rem;
  height: auto;
  padding-top: 0.1rem;
`;
const CartButton = ({ children }) => (
  <Fragment>
    <CartButtonWrapper>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const totalInCart = get(result, 'data.cart.productTotal', {
            incVat: 0,
            exVat: 0
          });
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);
          return (
            <Above breakpoint="lg">
              {matches =>
                // Use a flyout for breakpoints above sm // Else use a Drawer
                matches ? (
                  <FlyoutTrigger id="cart-flyout" coverStyles={{ zIndex: 998 }}>
                    {flyout => (
                      <Button
                        data-testid="header-cart"
                        onClick={
                          flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                        }
                      >
                        <Fragment>
                          <CartIcon />
                          <Price price={totalInCart} />
                          <Carrot />
                        </Fragment>
                      </Button>
                    )}
                  </FlyoutTrigger>
                ) : (
                  <DrawerTrigger
                    preventOverflow={true}
                    id="cart-drawer"
                    coverStyles={{ zIndex: 998 }}
                  >
                    {drawer => (
                      <Button
                        data-testid="header-cart"
                        onClick={
                          drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                        }
                      >
                        <CartIcon />
                        {children}
                        <Badge>{itemsInCart}</Badge>
                      </Button>
                    )}
                  </DrawerTrigger>
                )
              }
            </Above>
          );
        }}
      </CartProvider>
    </CartButtonWrapper>
  </Fragment>
);

export default CartButton;
