import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'smalltraveller',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    channelsQuery
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  disableGeoRedirect: true,
  intl: {
    translations,
    defaultLocale: 'sv',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          },
          DKR: {
            style: 'currency',
            currency: 'DKK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NKR' },
          AUD: { style: 'currency', currency: 'USD' }
        }
      }
    }
  },
  notFoundPageCategoryId: 1411,
  newsCategoryId: 1181,
  notFoundPageCategoryIds: [1128, 1126, 1137, 1167, 1160, 1116],
  channelOverrides: {},
  deprecations: { useOldProductVariants: true, useOldFilterAPI: true }
};
