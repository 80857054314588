import React from 'react';
import styled from 'react-emotion';
import ImageComp from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { Link } from 'react-router-dom';

import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';

import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';
//import ProductLink from '@jetshop/ui/ProductLink';

const Image = styled(ImageComp)`
  padding-top: 100%;
`;
const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 1rem;
  > :first-child {
    flex: 0 1 25%;
    margin-right: 1rem;
  }
`;

const ProductName = styled('div')`
  color: ${p => p.theme.colors.primary} !important;
  font-size: 1.1rem;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubText = styled.span`
  font-size: 0.9rem;
  color: #333;
`;

const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
  min-width: 75%;
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  text-align: right;
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: #f2f2f2;
    color: black;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
  }
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #8f8f8f;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 8px;
    width: 8px;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    //variantImage,
    variantValues
    //hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );
  return (
    <Wrapper className={className}>
      <div>
        {item.product.images.length > 0 && (
          <Image
            aspect={'1:1'}
            sizes="5rem"
            cover={false}
            src={item.product.images[0].url}
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductName>
            <Link to={item.product.primaryRoute.path}>
              <h2 data-testid="item-name">{item.product.name}</h2>
            </Link>
          </ProductName>
          <SubTextWrapper>
            {isVariant &&
              variantNamesValues.map((variantOptionNameValue, index) => (
                <SubText className="variant-value" key={index}>
                  {variantOptionNameValue}
                </SubText>
              ))}
            <SubText>{item.articleNumber}</SubText>
          </SubTextWrapper>
        </ItemDetails>

        <RemoveItem>
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            +
          </button>
        </AdjustQty>

        <LinePrice price={item.total} />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
