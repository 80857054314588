import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import Helmet from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import Theme from './Theme';
import ToastContainer from './ToastContainer';
import Popup from './StartPage/Popup';

import loadable from '@loadable/component';
import LoadingPage from './LoadingPage';

import GenericError from '@jetshop/ui/ErrorBoundary/Generic';

import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  { fallback: <LoadingPage /> }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  { fallback: <LoadingPage /> }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  { fallback: <LoadingPage /> }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  { fallback: <LoadingPage /> }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { selectedChannel } = useContext(ChannelContext);
  const channelEU = selectedChannel.id === 5;

  return (
    <GenericError>
      <ModalProvider>
        <Theme>
          <Container>
            <PaginationProvider defaultProductsPerPage={40}>
              <Helmet defaultTitle={selectedChannel.name} />
              <ToastContainer />
              <Header />
              <Content>
                <Switch>
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path="/search" component={LoadableSearchPage} />
                  <Route path="/signup" component={LoadableSignUpPage} />
                  <Route path="/login" component={LogInPage} />
                  <Route path="/my-pages" component={LoadableMyPages} />
                  <Route path="/stores" component={StoreLocator} />
                  <Route path="/store/:id" component={Store} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <Route
                    path="/preview"
                    component={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        categoryQuery={CategoryPreviewQuery}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </Content>
              <Footer />
              <ModalRoot />
              <ScrollRestorationHandler />
              {channelEU && <Popup />}
            </PaginationProvider>
          </Container>
        </Theme>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
