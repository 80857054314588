import t from '@jetshop/intl';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import React from 'react';
import styled from 'react-emotion';

import { theme } from '../../../Theme';

const StyledSelectorBody = styled(SelectorBody)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 20px;
  ${theme.below.lg} {
    padding: 20px 10px;
  }

  > div {
    width: 33%;
    ${theme.below.lg} {
      width: 50%;
    }
  }
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    border: 1px solid #000000;
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: #000000;
  }
`;

const StyledCommitButton = styled(CommitButton)`
  width: 100%;
  max-width: 100% !important;
  background: #662d91; // ${theme.colors.checkoutButton.background};
  width: auto;
  margin: 20px;
  ${theme.below.lg} {
    margin: 10px;
  }
`;
const StyledCancelButton = styled(CancelButton)`
  max-width: 100% !important;
  background: #f5f5f5;
  width: auto;
  margin: 20px;
  ${theme.below.lg} {
    margin: 10px;
  }
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel
}) => {
  const Comp = type;

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        languageItems,
        selectedChannel,
        hasChanged,
        onLanguageSelect
      }) => (
        <Comp style={type === MiniSelector ? { width: '100%' } : null}>
          <StyledSelectorBody>
            <ChecboxGroupContainer>
              <CheckboxGroup
                selectedItem={selectedChannel.language.name}
                items={languageItems}
                handleChange={onLanguageSelect}
                groupLabel={t('Language')}
              />
            </ChecboxGroupContainer>
          </StyledSelectorBody>
          {hasChanged ? (
            <StyledCommitButton
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text={t('Update')}
            />
          ) : (
            <StyledCancelButton
              text={t('Close')}
              onClick={() => hideTarget()}
            />
          )}
        </Comp>
      )}
    />
  );
};

export default Selector;
