import React from 'react';
import styled from 'react-emotion';
import { default as StickyComponent } from 'react-stickynode';

import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import theme from '@jetshop/ui/utils/theme';

import CategoryLink from '@jetshop/ui/CategoryLink';
import SubMenu from './SubMenu';

const Menu = styled('div')`
  width: 100%;
`;

const Wrapper = styled('div')`
  position: relative;
  background: ${theme('colors.primary')};
  top: 0;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: ${theme('general.maxWidth')};
    margin: 0 auto;
  }

  a {
    color: inherit;
    flex: 1 auto;
    width: 100%;
    display: block;
  }
`;

const Link = styled(CategoryLink)`
  padding: 0 14px;
  font-size: ${({ theme }) => theme.fontSize.header.nav};
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-weight: bold;

  &.active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.menu.backgroundActive};
    :hover {
      background-color: ${({ theme }) =>
        theme.colors.menu.backgroundActiveHover};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.menu.backgroundHover};
  }
`;

const MainMenuItem = styled('li')`
  color: ${({ theme }) => theme.colors.white};
  line-height: 40px;
  height: 40px;

  :hover {
    background-color: ${({ theme }) => theme.colors.active};
    > section {
      display: block;
    }
  }
  &.no-hover section {
    display: none;
  }
`;

const Sticky = styled(StickyComponent)`
  width: 100%;

  .sticky-inner-wrapper {
    z-index: 1;
  }

  &.active {
    .sticky-inner-wrapper {
      z-index: 1000;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    }
  }
`;

const ignoreCategory = 1286; //['att resa med barn'];

const MenuContainer = ({ categories }) => {
  return (
    <Menu>
      <UIMenuContainer>
        {props => (
          <Sticky>
            <Wrapper>
              <ul>
                {categories.map(cat => {
                  if (cat.id === ignoreCategory) {
                    return null;
                  }

                  return (
                    <MainMenuItem key={cat.id} className={'MainMenu'}>
                      <Link category={cat}>{cat.name}</Link>
                      {cat.subcategories.length > 0 && (
                        <SubMenu categories={cat.subcategories} parent={cat} />
                      )}
                    </MainMenuItem>
                  );
                })}
              </ul>
            </Wrapper>
          </Sticky>
        )}
      </UIMenuContainer>
    </Menu>
  );
};

export default MenuContainer;
