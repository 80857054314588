import { baseTheme } from '@jetshop/ui/BaseTheme';

function calculateWidth(items, margin) {
  const itemsToHaveMargin = items - 1;
  const itemWidth = (100 - itemsToHaveMargin * margin) / items;

  const style = `
    width: ${itemWidth}%;
    margin-right: ${margin}%;

    &:nth-child(${items}n+0) {
      margin-right: 0;
    }
  `;

  return style;
}

const fontFamilies = {
  header: '"Helvetica Neue", Helvetica, Arial, sans-serif;',
  body: '"Helvetica Neue", Helvetica, Arial, sans-serif;',
  general: '"Helvetica Neue", Helvetica, Arial, sans-serif;'
};

const typo = {
  fontWeightNormal: 400,
  fontWeightBold: 700,
  fontWeightLight: 200
};

const fontSize = {
  header: {
    nav: '14px'
  },
  breadCrumb: '13px'
};

const colors = {
  ...baseTheme.colors,
  red: '#E82A2A',
  font: '#333',
  primary: '#662d91',
  active: '#2f1543',
  primaryDarker: '#783838',
  orange: '#f7931d',
  secondary: '#666',
  buy: '#009900',
  border: '#EEE',
  checkoutButton: {
    background: '#79aa07',
    boxShadow: '#c9dd3d',
    hover: '#567905'
  },
  newsletterButton: {
    background: '#662d91',
    hover: '#4b216a'
  },
  menu: {
    background: '#662d91',
    backgroundActive: '#2f1543',
    backgroundActiveHover: '#14091c',
    backgroundHover: '#3d1b57',
    subBackground: '#441e61',
    subBackgroundHover: '#4f2b6a'
  },
  footer: {
    background: '#f8f8f8'
  },
  product: {
    name: '#333333',
    subName: '#888888',
    price: '#662d91',
    discountPrice: '#c60f12'
  },
  leftaside: {
    category: '#f7931d',
    information: '#662d91'
  },
  blog: {
    background: '#f8f8f8',
    categoryName: '#444444',
    categoryParentName: '#444444',
    content: '#444444'
  },
  main: {
    background: '#ffffff'
  },
  greys: [
    '#F0F0F0',
    '#DEDEDE',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#282828',
    '#F9F9F9',
    '#CCCCCC',
    '#B2B2B2',
    '#CDCDCD'
  ],
  price: ['#c60f12', '#333333'],
  header: {
    searchIcon: '#ac5050'
  }
};

const general = {
  maxWidth: '1200px;'
};

const header = {
  background: colors.greys[6],
  height: {
    sm: '1em',
    md: '2em',
    lg: '10em'
  }
};

export const barnResebutikenTheme = {
  ...baseTheme,
  general,
  fontFamilies,
  fontSize,
  colors,
  header,
  typo,
  calculateWidth
};
