import t from '@jetshop/intl';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody,
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import React, { useState } from 'react';
import styled from 'react-emotion';
import flattenCountries from './flattenCountries';

import EuFlag from '../../../../img/eu-flag.png';

import { theme } from '../../../Theme';
import { cx } from 'emotion';

const StyledSelectorBody = styled(SelectorBody)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 20px;
  ${theme.below.lg} {
    padding: 20px 10px;
  }

  > div {
    width: 33%;
    ${theme.below.lg} {
      width: 50%;
    }
  }
`;

const Country = styled('div')`
  height: 42px;
  font-size: 12px;
  background-color: ${({ isActive }) => (isActive ? '#ebebeb' : 'transparent')};
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  background: transparent;
  color: ${({ isActive }) => (isActive ? '#000' : '#4f4f4f')};
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.75')};

  &.isActive {
    color: '#000';
    font-weight: '600';
    opacity: '1';
    background: #ebebeb;
  }

  display: flex;
  align-items: center;
  :hover {
    opacity: 1;
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 42px;
  margin-right: 10px;
  ${theme.below.lg} {
    margin-right: 5px;
  }
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    border: 1px solid #000000;
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: #000000;
  }
`;

const StyledTitle = styled(Title)`
  color: #000;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 4px;
  font-size: 1.3rem;
  padding: 20px 0 10px 0;
  ${theme.below.lg} {
    font-size: 1rem;
  }
`;
const StyledCommitButton = styled(CommitButton)`
  width: 100%;
  max-width: 100% !important;
  background: #662d91; // ${theme.colors.checkoutButton.background};
  width: auto;
  margin: 20px;
  ${theme.below.lg} {
    margin: 10px;
  }
`;
const StyledCancelButton = styled(CancelButton)`
  max-width: 100% !important;
  background: #f5f5f5;
  width: auto;
  margin: 20px;
  ${theme.below.lg} {
    margin: 10px;
  }
`;
const StyledLangCurrWrapper = styled(LangCurrWrapper)`
  display: none;
`;

const Button = ({
  name,
  code,
  channel,
  selectedChannel,
  onSelect,
  src,
  euChannel = false
}) => (
  <Country
    key={channel.id + name}
    className={cx(
      channel.id === selectedChannel.id &&
        name === selectedChannel.country.name &&
        'isActive'
    )}
    onClick={() => onSelect(channel.id, null, null, code)}
  >
    <CountryFlag src={src} alt="Country Flag" width="16" height="12" />
    {euChannel ? 'Europe' : name} ({channel.defaultCurrency.name})
  </Country>
);

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel
}) => {
  const Comp = type;
  // const [countries] = useState(flattenCountries(channels));

  //FILTER OUT UNRELEASED CHANNELS
  const filteredChannels = channels.filter(channel => {
    return !channel?.name.match(/Lettland|Litauen/i);
  });

  return (
    <ChannelSelector
      channels={filteredChannels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => (
        <Comp style={type === MiniSelector ? { width: '100%' } : null}>
          <StyledTitle>{t('Select your country')}</StyledTitle>
          <StyledSelectorBody>
            {filteredChannels.map(channel => {
              const defaultCountry = channel.countries.find(c => c.isDefault);

              // EU channel
              if (channel.id === 5) {
                return (
                  <Button
                    channel={channel}
                    selectedChannel={selectedChannel}
                    onSelect={onSelect}
                    src={EuFlag}
                    euChannel
                    {...defaultCountry}
                  />
                );
              }

              return (
                <Button
                  channel={channel}
                  selectedChannel={selectedChannel}
                  onSelect={onSelect}
                  src={`https://countryflags.jetshop.io/${defaultCountry.code}/flat/32.png`}
                  {...defaultCountry}
                />
              );
            })}
            {/* {countries.map(({ name, code, channel }) => {
              if (channel.id === 5) {
                return (
                  <Button
                    channel={channel}
                    selectedChannel={selectedChannel}
                    onSelect={onSelect}
                    name={name}
                    src={EuFlag}
                  />
                );
              }

              return (
                <Button
                  name={name}
                  channel={channel}
                  selectedChannel={selectedChannel}
                  onSelect={onSelect}
                  src={`https://countryflags.jetshop.io/${code}/flat/32.png`}
                />
              );
            })} */}
          </StyledSelectorBody>
          <StyledLangCurrWrapper>
            <ChecboxGroupContainer>
              <CheckboxGroup
                selectedItem={selectedChannel.language.name}
                items={languageItems}
                handleChange={onLanguageSelect}
                groupLabel={t('Language')}
              />
              <CheckboxGroup
                selectedItem={selectedChannel.currency.name}
                items={currencyItems}
                handleChange={onCurrencySelect}
                groupLabel={t('Currency')}
              />
            </ChecboxGroupContainer>
          </StyledLangCurrWrapper>
          {hasChanged ? (
            <StyledCommitButton
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text={t('Update')}
            />
          ) : (
            <StyledCancelButton
              text={t('Close')}
              onClick={() => hideTarget()}
            />
          )}
        </Comp>
      )}
    />
  );
};

export default Selector;
