import styled from 'react-emotion';

const CloseButton = styled('button')`
  position: absolute;
  top: 50%;
  right: 1rem;
  font-weight: bold;
  background: none;
  font-size: 1rem;
  margin-top: -0.7rem;
`;

export default CloseButton;
