import React from 'react';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import MobileCategoriesComp from './Categories/MobileCategories';
import { LinkElemnts } from '../Footer/FooterLinks';
import t from '@jetshop/intl';
import CloseButton from '../../ui/CloseButton';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';

const FlyoutHeader = styled('h2')`
  position: relative;
  text-align: center;
  font-weight: ${theme('typo.fontWeightBold')};
  font-family: ${theme('fontFamilies.heavy')};
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

const Flyout = styled('div')`
  background: ${theme('colors.white')};
  color: ${theme('colors.black')};
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const LinkList = styled('nav')`
  a {
    padding: 0.6rem 0;
    font-size: 1.1rem;
    color: black;
    text-decoration: none;
    display: block;
    + div {
      margin-left: 1rem;
    }
  }
`;

const MobileMenuWrapper = styled('div')`
  padding: 1rem;
`;

const MobileCategories = styled(MobileCategoriesComp)``;

const MobileMenuView = ({ drawer, categories }) => {
  if (categories === undefined) {
    return null;
  }

  return (
    <TouchScrollable>
      <Flyout>
        <FlyoutHeader>
          {t('Meny')}
          <CloseButton onClick={drawer.hideTarget}>&#x2715;</CloseButton>
        </FlyoutHeader>
        <MobileMenuWrapper>
          <MobileCategories
            categories={categories}
            closeMenu={drawer.hideTarget}
          />
          <LinkList aria-label="Footer">
            <LinkElemnts
              includePredefined={false}
              onClick={drawer.hideTarget}
            />
          </LinkList>
        </MobileMenuWrapper>
        <LanguageSelector />
      </Flyout>
    </TouchScrollable>
  );
};

export default ({ categories }) => {
  return (
    <DrawerTarget id="mobile-drawer">
      {drawer => (
        <>
          {drawer.isOpen && <ScrollLock />}
          <Drawer isOpen={drawer.isOpen} left>
            <MobileMenuView drawer={drawer} categories={categories} />
          </Drawer>
        </>
      )}
    </DrawerTarget>
  );
};
