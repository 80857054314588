import React from 'react';
import styled from 'react-emotion';

// import { SearchFieldBehaviour } from '@jetshop/ui/Search';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import SearchInput from '../../../../components/Layout/Header/Search/SearchInput';
import SearchFlyout from '../../../../components/Layout/Header/Search/SearchFlyout';
import { SearchButton } from '../../../../components/Layout/Header/Search';
import posed from 'react-pose';
import autocompleteQuery from '../AutocompleteQuery.gql';

export const FlyoutContainer = styled('div')`
  position: relative;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;

  ${p => p.theme.below.lg} {
    border: none;
  }

  button {
    margin-left: 1rem;
    background: none;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    left: 100%;
    top: 0;
    position: absolute;
    height: 100%;

    :focus,
    active {
      outline: none;
    }
    &:hover {
      opacity: 0.8;
    }
    ${({ theme }) => theme.below.sm} {
      left: auto;
    }
  }
`;

const PosedSearchField = styled(
  posed('div')({
    open: {
      width: '100%',
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration: 400,
        delay: 100
      },
      applyAtStart: {
        pointerEvents: 'all'
      }
    },
    closed: {
      width: '0%',
      opacity: 0,
      applyAtEnd: {
        pointerEvents: 'none'
      }
    }
  })
)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  // width: 100%;
  transform-origin: left;
  z-index: 5;
  ${({ theme }) => theme.below.sm} {
    width: 100%;
    margin: 0;
  }

  ${({ theme }) => theme.below.lg} {
    overflow-x: hidden;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  position: relative;

  ${({ theme }) => theme.below.lg} {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const SearchField = ({
  className = '',
  isOpen,
  onOpen,
  alwaysOpen,
  ...searchFieldProps
}) => (
  <SearchAutoCompleteContainer
    autocompleteQuery={autocompleteQuery}
    {...searchFieldProps}
    onSubmit={searchFieldProps.onCancel}
    focusOnLoad={!alwaysOpen}
  >
    {({
      getInputProps,
      getFlyoutProps,
      getCancelProps,
      updateFocus,
      ...rest
    }) => (
      <Wrapper>
        <PosedSearchField pose={isOpen ? 'open' : 'closed'}>
          <FlyoutContainer className={className}>
            <SearchInput getInputProps={getInputProps} {...rest} />
            <SearchButton
              searchIsOpen={isOpen}
              onSearchOpen={() => {
                onOpen();
                updateFocus('focus');
              }}
              dirty={rest.isDirty && rest.hasFocus}
            />
            <SearchFlyout
              getFlyoutProps={getFlyoutProps}
              closeSearch={searchFieldProps.onCancel}
              {...rest}
            />
          </FlyoutContainer>
        </PosedSearchField>
      </Wrapper>
    )}
  </SearchAutoCompleteContainer>
);

export default SearchField;
