import React from 'react';
import styled from 'react-emotion';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import theme from '@jetshop/ui/utils/theme';
import IntlContext from '@jetshop/intl/context';

const Input = styled('input')`
  border: 0px solid #fd9e2f;
  width: calc(100% - 2.85rem);
  background-color: ${theme('colors.white')};
  color: ${theme('colors.black')};
  font-size: 0.9em;
  outline: none;
  height: 40px;
  padding: 1rem;
  color: #b1b1b1;
  border-radius: 5px;
  display: block;
  transition: 0.5s;
  font-style: italic;

  &:focus {
    background-color: ${theme('colors.white')};
    color: ${theme('colors.black')};
  }
  ${({ theme }) => theme.below.lg} {
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 16px;
  }
`;

const SearchInput = ({ isDirty, getInputProps }) => (
  <FlyoutTrigger id="searchFlyout" showCover={false}>
    {({ showTarget, hideTarget }) => (
      <IntlContext.Consumer>
        {t => (
          <Input
            type="search"
            {...getInputProps({
              onFocus: showTarget,
              refKey: 'innerRef'
            })}
            isDirty={isDirty}
            placeholder={t('Search the store')}
          />
        )}
      </IntlContext.Consumer>
    )}
  </FlyoutTrigger>
);

export default SearchInput;
