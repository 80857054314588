import React from 'react';
import styled, { css } from 'react-emotion';

import ToastContainerWrapper from './Cart/ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
  ${p => p.theme.above.sm} {
    width: unset;
  }
`;

export default () => (
  <ToastContainerWrapper>
    <StyledToastContainer
      toastClassName={css`
        padding: 0px;
        cursor: auto;
        font-family: inherit;
      `}
      autoClose={false}
      hideProgressBar
      closeButton={false}
      closeOnClick={false}
    />
  </ToastContainerWrapper>
);
