import React, { Component } from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import t from '@jetshop/intl';
import { ReactComponent as CarrotSvg } from '../../../../svg/Carrot.svg';

const Carrot = styled(CarrotSvg)`
  fill: #f7931d;
  transform: scale(1) rotate(-90deg);
  display: block;
  margin-top: 0.4rem;
  margin-left: 1rem;
`;

const List = styled('ul')`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ListItem = styled('li')`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.menu.subBackground};
  align-self: flex-start;
  width: 100%;
  :hover {
    background: ${({ theme }) => theme.colors.menu.subBackgroundHover};
    > section {
      border-left: 1px solid ${({ theme }) => theme.colors.greys[10]};
      display: block;
      left: 100%;
      top: 0;
      ul > li {
        border-bottom: 1px solid ${({ theme }) => theme.colors.greys[10]};
        &:last-child: {
          border: none;
        }
      }
    }
  }

  a {
    line-height: 1rem;
  }
`;

const Link = styled(CategoryLink)`
  white-space: nowrap;
  line-height: 1.3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex !important;
  justify-content: space-between;
`;

const Container = styled('section')`
  display: none;
  position: absolute;
  background: ${({ theme }) => theme.colors.active};
  z-index: 999;
  &.SubMenu {
    ${ListItem} {
    }
  }
`;

const maxItemsOnParent = {
  1189: 14 // Varumärke
};

export default class SubMenu extends Component {
  render() {
    const { categories, parent, subMenu } = this.props;
    const maxItems = maxItemsOnParent[parent.id] || false;
    return (
      <Container>
        <List>
          {categories.map((category, index) => {
            if (maxItems && index > maxItems - 1) {
              return null;
            }
            const hasSubcategories =
              category.subcategories && category.subcategories.length > 0;

            // if (category.name.toLowerCase() === 'att resa med barn') {
            //   category.primaryRoute = {
            //     id: '/att-resa-med-barn',
            //     path: '/att-resa-med-barn',
            //     __typename: 'Route'
            //   };
            // }

            return (
              <ListItem key={category.id} className={subMenu && 'subMenu'}>
                <Link category={category}>
                  {category.name}
                  {hasSubcategories && <Carrot />}
                </Link>
                {hasSubcategories && (
                  <SubMenu
                    categories={category.subcategories}
                    parent={category}
                    subMenu={true}
                  />
                )}
              </ListItem>
            );
          })}
          {maxItems && (
            <ListItem className="showAll" key={parent.id}>
              <Link category={parent}>{t('Show all')}</Link>
            </ListItem>
          )}
        </List>
      </Container>
    );
  }
}
