import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';

import { barnResebutikenTheme } from './Barnresebutiken';

export const theme = barnResebutikenTheme;

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node
};

injectGlobal`
  html, body {
    font-family: ${theme.fontFamilies.body};
    font-size: 14px;
    line-height: 1.5;
  }
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
    height: 100%;
    &.lock-scroll {
      position: fixed;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-overflow-scrolling: auto;
      overflow: hidden;
      zoom: 100%
    }
  }

  body {
    position: relative;
    overflow: auto;
    pointer-events: auto;
    height: 100%;
    background: url(https://www.barnresebutiken.se/stage/images/custom/background.jpg) no-repeat top #b9988e;
    color: #333;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  strong {
    font-weight: bold;
  }
`;

export default Theme;
