import React from 'react';
import boot from '@jetshop/core/boot/client';
import Shop from './components/Shop';
import config from './shop.config';
/*import Raven from 'raven-js';

Raven.config(config.sentryClientDSN, {
  tags: { BUILD_TARGET: 'client' }
}).install(); */
boot(<Shop />, config);
