import React from 'react';
import styled, { css } from 'react-emotion';
import t from '@jetshop/intl';

import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import UISuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';

const flyout = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 2.7rem;
  background: white;
  padding: 1rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07);
  white-space: normal;
`;

const SuggestedTerm = styled(UISuggestedTerm)`
  list-style: none;
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.black};
    display: block;
    white-space: normal;
    :hover {
      opacity: 0.7;
    }
  }
`;

const SearchFlyout = searchProps => (
  <FlyoutTarget id="searchFlyout">
    {flyoutProps =>
      searchProps.isOpen &&
      flyoutProps.isOpen &&
      searchProps.isDirty && (
        <div
          className={`search-flyout ${flyout}`}
          {...searchProps.getFlyoutProps()}
        >
          {searchProps.loading
            ? t('Loading…')
            : searchProps.result?.products?.result?.length > 0
            ? searchProps.result?.products?.result?.map(prod => (
                <SuggestedTerm
                  key={prod.id}
                  item={prod}
                  // onClick={searchProps.closeSearch}
                  onClick={flyoutProps.hideTarget}
                />
              ))
            : t('No matching products found')}
        </div>
      )
    }
  </FlyoutTarget>
);

export default SearchFlyout;
