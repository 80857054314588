import React from 'react';
import styled from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import { ReactComponent as EnvelopeSvg } from '../../../svg/envelope-solid.svg';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 31rem;
  /* margin: 0 auto; */
  background: white;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  ${({ theme }) => theme.above.md} {
    margin-bottom: 2rem;
  }

  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4rem 0 1rem;
    border: 1px solid #bfbdbd;
  }
  button {
    all: unset;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.newsletterButton.background};
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    height: 100%;
    cursor: pointer;
    padding: 0 0.5rem 0 0.5rem;
    :hover {
      background: ${({ theme }) => theme.colors.newsletterButton.hover};
    }
  }
`;

const Envelope = styled(EnvelopeSvg)`
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

const Head = styled('div')`
  display: flex;
  margin-bottom: 0.5rem;
  h2 {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const SubmitButton = styled.button`
  &:disabled {
    opacity: 0.5;
  }
`;

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  submitEmail = submitFn => e => {
    e.preventDefault();
    submitFn(this.state.email);
  };

  render() {
    const { error, completed, email } = this.state;
    const isValid = EMAIL_REGEX.test(email);

    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => (
          <React.Fragment>
            <Head>
              <h2>{t('Newsletter')}</h2>
              <Envelope />
            </Head>
            {error && (
              <Error>
                {t(
                  'Something went wrong. Please check your email and try again.'
                )}
              </Error>
            )}
            <Wrapper>
              {/* Show the completion success message once signed up, otherwise show the form */}
              {completed ? (
                <p>{t('You are now subscribed')}</p>
              ) : (
                <form onSubmit={this.submitEmail(subscribe)}>
                  <Intl>
                    {/* This is necessary to use t for the placeholder string */}
                    {t => (
                      <input
                        type="email"
                        placeholder={t('Enter email address')}
                        onChange={this.updateEmail}
                      />
                    )}
                  </Intl>
                  <SubmitButton disabled={!isValid}>
                    {/* Display 'Submitting…' while the form is posting */}
                    {result.loading ? t('Submitting…') : t('Submit')}
                  </SubmitButton>
                </form>
              )}
            </Wrapper>
          </React.Fragment>
        )}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
