//import Analytics from '@jetshop/core/analytics/Analytics';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React from 'react';
import styled from 'react-emotion';
import Button from '../ui/Button';
import CloseButton from '../ui/CloseButton';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';

const Flyout = styled('div')`
  background: white;
  color: ${theme('colors.black')};
  ${({ theme }) => theme.above.sm} {
    position: absolute;
    z-index: 999;
    left: 50%;
    top: ${p => p.scrollTop + 100}px;
    max-width: 30rem;
    width: 100%;
    transform: translateX(-50%);
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
`;

const Header = styled('header')`
  background: #fff;
  position: relative;
  text-align: center;
  h2 {
    font-weight: 600;
    font-family: ${theme('fontFamilies.heavy')};
  }
  ${LightText} {
    display: block;
  }
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartItems = styled('section')`
  background: #fff;
  padding: 1rem;
`;

const Summary = styled('section')`
  background: #fff;
  padding: 1rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
`;

const TotalSummary = styled('div')`
  font-size: 1.25rem;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  color: ${theme('colors.black')};
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled(Button)`
  display: flex;
  background: ${p => p.theme.colors.checkoutButton.background};
  border-color: ${p => p.theme.colors.primary};
`;

const Buttons = styled.div`
  display: flex;
  padding: 1rem;

  ${p => p.theme.below.lg} {
    flex-direction: column;
  }
`;

const ContinueShopping = styled(Button)`
  background: ${p => p.theme.colors.primary};

  ${p => p.theme.above.lg} {
    margin-right: 1rem;
  }

  ${p => p.theme.below.lg} {
    margin-bottom: 1rem;
  }

  &:only-child {
    margin: 0 !important;
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const track = useTracker();
  const items = get(result, 'data.cart.items', []);
  const itemCount = get(result, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');

  return (
    <Flyout {...rest} scrollTop={window.scrollY}>
      <Header>
        <h2>{t('Your cart')}</h2>
        <ItemCount count={itemCount} />
        <CloseButton onClick={modal.hideTarget}>&#x2715;</CloseButton>
      </Header>

      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>
      <Summary>
        <TotalSummary>
          <label>{t('Total')}</label>
          <Price price={result.data.cart.productTotal} />
        </TotalSummary>
      </Summary>

      <Buttons>
        <ContinueShopping onClick={modal.hideTarget}>
          {t('Continue shopping')}
        </ContinueShopping>
        {checkoutUrl && (
          <Checkout
            anchor={true}
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Buttons>
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <FlyoutTarget id="cart-flyout">
                {flyout => (
                  <CartFlyoutView modal={flyout} result={result} {...props} />
                )}
              </FlyoutTarget>
            ) : (
              <DrawerTarget id="cart-drawer">
                {drawer => (
                  <Drawer isOpen={drawer.isOpen} right>
                    <CartFlyoutView modal={drawer} result={result} {...props} />
                  </Drawer>
                )}
              </DrawerTarget>
            )
          }
        </Above>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
