import React, { useContext } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { Link } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const StoreName = styled('h1')`
  font-size: 2rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  margin: 0.5rem;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StoreLogo = styled('img')`
  display: block;
  ${theme('below.lg')} {
    max-width: 15rem;
  }

  ${theme('below.md')} {
    max-width: 13rem;
  }
`;

const LogoLink = styled(Link)`
  display: block;
`;

const logoUrls = {
  1: '/production/images/smalltraveller.png',
  2: '/production/images/matkamuksu-small.png',
  3: '/production/images/reisipisik.png',
  4: '/pub_images/original/Smalltraveller.dk-logotype.png',
  5: '/pub_images/original/smalltraveller-eu.png',
  6: '/pub_images/original/smalltraveller.lv.png',
  7: '/pub_images/original/smalltraveller-lt.png'
};

const Logo = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const logoSrc = logoUrls[selectedChannel.id];

  return (
    <StoreName>
      <LogoLink to="/">
        <StoreLogo
          alt={`${selectedChannel.name} Logo`}
          src={selectedChannel.imageUrl + logoSrc}
        />
      </LogoLink>
    </StoreName>
  );
};

export default Logo;
