//import gql from '@jetshop/core/gql';
import get from 'lodash.get';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import pagesQueryWithStartpage from './PagesQueryWithStartpage.gql';

import { ReactComponent as InfoIconSvg } from '../../../svg/info-circle-solid.svg';

import HeaderChannelSelector from '../Header/ChannelSelector/ChannelSelector';

const Conatiner = styled('section')`
  a {
    font-size: 14px;
  }
`;

const InfoIcon = styled(InfoIconSvg)`
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
`;

const Head = styled('div')`
  margin-bottom: 0.5rem;
  display: flex;

  h2 {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  &.fix-order {
    &:nth-child(4) {
      order: 3;
    }

    &:nth-child(3) {
      order: 4;
    }
  }
`;

const startpage = {
  name: t('Home'),
  id: 0,
  primaryRoute: {
    id: 1,
    path: '/'
  }
};

const codesToFix = ['EE', 'FI'];

export const LinkElemnts = withRouter(({ pages, onClick, location }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const countryCode = selectedChannel.country.code;
  return (
    <Query query={pagesQueryWithStartpage}>
      {({ data, loading }) => {
        if (loading) {
          return null;
        }

        const fetchedPages = get(data, 'pages', []);
        const fetchedCategory = get(data, 'category', []);

        const { pathname } = location;

        const pages = fetchedCategory
          ? [startpage, ...fetchedPages, fetchedCategory]
          : [startpage, ...fetchedPages];

        return pages ? (
          <List>
            {pages.map(page => (
              <ListItem
                key={page.id}
                className={
                  pages.length === 4 && codesToFix.includes(countryCode)
                    ? 'fix-order'
                    : ''
                }
              >
                {page.primaryRoute && (
                  <Link
                    key={page.id}
                    to={page.primaryRoute.path}
                    className={
                      page.primaryRoute.path === pathname ? 'active' : ''
                    }
                    onClick={onClick}
                  >
                    {page.name}
                  </Link>
                )}
              </ListItem>
            ))}
          </List>
        ) : null;
      }}
    </Query>
  );
});

const ChannelButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const FooterLinks = () => (
  <Conatiner>
    <Head>
      <h2>{t('Info')}</h2>
      <InfoIcon />
    </Head>
    <nav aria-label="Footer">
      <LinkElemnts />
      <ChannelButtonWrapper>
        <HeaderChannelSelector />
      </ChannelButtonWrapper>
    </nav>
  </Conatiner>
);

export default FooterLinks;
