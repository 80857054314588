import React, { useState } from 'react';
import styled from 'react-emotion';
// import { cx } from 'emotion';
// import t from '@jetshop/intl';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
// import LargeSelector from '@jetshop/ui/ChannelSelector/LargeSelector';
// import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import EuFlag from '../../../../../img/eu-flag.png';
import { theme } from '../../../../Theme';

const Wrapper = styled('div')`
  border-radius: 2px;
  & > div:first-child {
    background: #ffffff;
  }
`;

const Button = styled.button`
  height: 42px;
  font-size: 14px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  color: #4f4f4f;
  font-weight: 400;
  opacity: 0.75;
  background-color: transparent;
  width: 100%;

  &.isActive {
    background-color: #ebebeb;
    color: #000;
    font-weight: 600;
    opacity: 1;
  }
  :hover {
    opacity: 1;
    background-color: #ebebeb;
  }

  &[data-active='true'] {
    background-color: #ebebeb;
  }
`;

const CountryFlag = styled('img')`
  height: 12px;
  width: 16px;
  margin-right: 15px;
`;

const StylesWrapper = styled('div')`
  label {
    display: inline-flex;
  }

  ${LangCurrWrapper} {
    background: #f9f9f9;
    margin-bottom: 0;
  }
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    border: 1px solid ${theme.colors.blue};
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: ${theme.colors.blue};
  }
`;

const StyledChannelSelector = styled(ChannelSelector)`
  border: none;
  border-radius: 2px;
`;

const StyledSelectorBody = styled(SelectorBody)`
  padding: 0;
  border-radius: 2px;
`;

const buttonCss = `
  margin: 0;
  width: 100%;
  border-radius: 0 0 2px 2px;
`;

const StyledCommitButton = styled(CommitButton)`
  ${buttonCss}
  background-color: ${theme.colors.primary};
  
  `;

const StyledCancelButton = styled(CancelButton)`
  ${buttonCss};
  border-top: 1px solid #ebebeb;
  :hover {
    background-color: #ebebeb;
  }
`;

const Selector = ({ channels, selectedChannel, updateChannel, hideTarget }) => {
  return (
    <StyledChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({ selectedChannel, onSelect, hasChanged }) => (
        <Wrapper>
          <StyledSelectorBody>
            {channels.map(channel => {
              const euChannel = channel.id === 5;
              const defaultCountryInChannel = channel.countries.find(
                c => c.isDefault
              );
              return (
                <Button
                  data-active={selectedChannel.id === channel.id}
                  key={channel.id}
                  onClick={() => {
                    onSelect(
                      channel.id,
                      null,
                      null,
                      defaultCountryInChannel.code
                    );
                  }}
                >
                  <CountryFlag
                    src={
                      euChannel
                        ? EuFlag
                        : `https://countryflags.jetshop.io/${defaultCountryInChannel.code}/flat/32.png`
                    }
                    alt="Country Flag"
                  />
                  {euChannel ? 'Europe' : defaultCountryInChannel.name} (
                  {channel.defaultCurrency.name})
                </Button>
              );
            })}
          </StyledSelectorBody>
          {hasChanged ? (
            <StyledCommitButton
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text="Update"
            />
          ) : (
            <StyledCancelButton text="Close" onClick={() => hideTarget()} />
          )}
        </Wrapper>
      )}
    />
  );
};

export default Selector;
