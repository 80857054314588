import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { Component, Fragment } from 'react';
import Selector from './Selector';
import styled from 'react-emotion';
import { theme } from '../../../Theme';

import EuFlag from '../../../../img/eu-flag.png';

const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 5;
  top: calc(100% + ((54px - 100%) / 2));

  position: fixed;
  margin: auto;
  width: auto;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  right: auto;

  width: 100%;
  max-width: 800px;
  > div {
    width: 100%;
    max-width: 800px;
    border: 0;
  }

  ${theme.below.lg} {
    margin: 0 auto;
    width: 95%;
  }
`;

const CountryFlag = styled('img')`
  height: 20px;
  margin-right: 5px;
`;

const Button = styled.button`
  font-size: 14px;
  display: flex;
`;

export const ChannelSelectorFlyout = () => {
  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => (
        <Fragment>
          <FlyoutTarget id="channel-selector">
            {({ isOpen, hideTarget }) => (
              <Flyout>
                <Selector
                  channels={channels}
                  hideTarget={hideTarget}
                  selectedChannel={selectedChannel}
                  updateChannel={updateChannel}
                />
              </Flyout>
            )}
          </FlyoutTarget>
        </Fragment>
      )}
    </ChannelContext.Consumer>
  );
};

export default class HeaderChannelSelector extends Component {
  render() {
    return (
      <ChannelContext.Consumer>
        {({ channels, selectedChannel, updateChannel }) => (
          <Fragment>
            <FlyoutTrigger id="channel-selector">
              {({ showTarget, hideTarget, isOpen }) => {
                const euChannel = selectedChannel.id === 5;
                return (
                  <Button
                    onClick={isOpen ? hideTarget : showTarget}
                    style={{ background: 'transparent' }}
                  >
                    <CountryFlag
                      src={
                        euChannel
                          ? EuFlag
                          : `https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/32.png`
                      }
                      alt="Country Flag"
                    />
                    {euChannel ? 'Europe' : selectedChannel.country.name} (
                    {selectedChannel.defaultCurrency.name})
                  </Button>
                );
              }}
            </FlyoutTrigger>
          </Fragment>
        )}
      </ChannelContext.Consumer>
    );
  }
}
