import React from 'react';
import MenuContainer from './ThreeTierMenu/MenuContainer';
import styled from 'react-emotion';

const Nav = styled('nav')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const MainMenu = ({ searchIsOpen, setSearchOpen, categories }) => {
  return (
    <Nav aria-label="Main">
      <MenuContainer categories={categories} />
    </Nav>
  );
};

export default MainMenu;
