import { Above } from '@jetshop/ui/Breakpoints';
import React from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import { ReactComponent as Icon } from './Search.svg';

const PosedContainer = posed('div')({});

const Container = styled(PosedContainer)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 1rem;
  text-align: right;
  width: 2.85rem;
  height: 2.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 5px;
`;

const SearchIcon = styled(Icon)`
  height: 100%;
`;
const IconWrapper = styled('div')`
  display: inline-block;
  height: 1rem;
  max-height: 100%;
  ${({ theme }) => theme.below.lg} {
    height: 1.25rem;
  }
  svg {
    fill: currentColor;
  }
`;

const SearchButton = ({ onSearchOpen, searchIsOpen, dirty }) => (
  <Above breakpoint="lg">
    <Container onClick={onSearchOpen}>
      <IconWrapper>
        <SearchIcon pose={searchIsOpen ? 'shifted' : 'default'} />
      </IconWrapper>
    </Container>
  </Above>
);

export default SearchButton;
