import React from 'react';
import styled from 'react-emotion';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.general.maxWidth};
`;

const MaxWidth = ({ className = '', children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default MaxWidth;
